@import "../../variables.module";

.feedbackContent {
  padding: $padding;
  min-height: calc(100vh - 83.19px);
}

.searchContainer {
  display: flex;
  // background-color: $main-bg;
  border-radius: 1.125rem;
  align-items: center !important;
  justify-content: space-around;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
}

.searchFilterNew {
  align-items: center;
  gap: 1rem;
}
.filterIcon {
  font-size: 1.5rem;
  color: $icon;
  cursor: pointer;
}
.searchIcon {
  color: $searchIcon;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}
.addText {
  color: $icon;
  font-size: 1.1rem;
}

.searchInput {
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #c1c1c1;
}
::placeholder {
  color: #c1c1c1;
}
.editDiv {
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: 10;
  width: fit-content;
  height: fit-content;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem 0 0.5rem 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.optionDiv {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $hr;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.optionDiv:last-child {
  border: none;
  margin-bottom: 0;
}
.modalSignup {
  height: 44rem;
  width: 43.875rem !important;
}
