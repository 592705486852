@import "../variables.module";

.editContent {
  padding: $padding;
}
.Container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem;
}
.title {
  font-size: $modal-topic-fs;
  font-weight: $modal-topic-fw;
  line-height: $modal-topic-lh;
  color: $modal-topic-clr;
}
.Container input,
.input,
.searchUser,
textarea {
  padding: 1.25rem;
  width: 100%;
  height: 3.25rem;
  background: #fafafa;
  border: 2px solid #e7e9ef;
  border-radius: 0.75rem;
  font-size: $modal-inp-fs;
  font-weight: $modal-inp-fw;
  color: $modal-inp-clr;
  line-height: $modal-fill-lh;
  outline: none;
}
.Container textarea {
  height: 10rem;
}
.select {
  padding: 1.25rem;
  width: 100%;
  height: 3.25rem;
  background: #fafafa;
  border: 2px solid #e7e9ef;
  border-radius: 0.75rem;
  font-size: $modal-inp-fs;
  font-weight: $modal-inp-fw;
  color: $modal-inp-clr;
  line-height: $modal-fill-lh;
  outline: none;
}
.subTitle,
.verifiedDiv {
  font-size: $modal-fill-fs;
  color: $modal-fill-clr;
  line-height: $modal-fill-lh;
}

.uploadDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.uploadBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: none !important;
  font-size: 1rem;
  color: #8998ae;
  font-weight: 500;
}
.uploadBtn:hover {
  color: #8998ae !important;
}
.uploadImgDiv {
  width: 35px;
  height: 35px;
  // border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e3ebf5;
}
.uploadText {
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 156.52%;
  text-align: center;
  color: #8998ae;
}
.verifiedDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.error {
  color: red;
}
.cancelButton:hover {
  color: #D54C4E !important;
}
.Btns button {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
}
.cancelBtn {
  font-weight: 500;
}
.okBtn {
  background-color: rgb(155, 8, 8);
  color: #fff;
  font-weight: 500;
}

.questionDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}
.eachQnDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.eachQnDiv input {
  width: fit-content;
}
.lastDonatedDate {
  width: 120%;
}

.editor {
  width: 100% !important;
}

.removeImage {
  cursor: pointer;
}
.list {
  border: 2px solid blue;
}
