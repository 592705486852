@import "../variables.module";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $loginBg;
}
.main {
  width: 400px;
  height: 500px;
  box-shadow: #e53232 0px 2px 8px 0px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  border-radius: $Tcard-br;
  background-color: white;
  padding: 0 $padding;
  position: relative;
}
.bgContainer {
  position: absolute;
  width: 100%;
  object-fit: cover;
  // top: 10rem;
  // height: 100%;
  top: 0;
  opacity: 0.1;
}
.logo {
  width: 200px;
}
.formDiv {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
