@import "../../variables.module";

.addCategoryContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem;
}

.title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #8998ae;
}
.addCategoryContainer input {
  padding: 1.25rem;
  width: 100%;
  height: 3.25rem;
  background: #fafafa;
  border: 2px solid #e7e9ef;
  border-radius: 0.75rem;
  font-size: $modal-inp-fs;
  font-weight: $modal-inp-fw;
  color: $modal-inp-clr;
  line-height: $modal-fill-lh;
  outline: none;
}
