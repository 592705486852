@import "../../variables.module";

.categoryContainer {
  padding: $padding;
}
.path {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 2rem;
}

.prevPath {
  cursor: pointer;
  font-weight: $prev-fw;
  line-height: $prev-lh;
  color: $prev-clr;
  font-size: $prev-fs;
}
.currPath {
  font-weight: $prev-fw;
  line-height: $prev-lh;
  color: $cur-clr;
  font-size: $prev-fs;
}
.heading {
  background-color: $categories-bg;
  width: 100%;
  height: 3rem;
  margin-top: 0.6875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem 0 2rem;
}
.headingText {
  font-weight: 700;
  line-height: $prev-lh;
  color: $heading-clr;
  font-size: $prev-fs;
}

.addBtn {
  border: none;
  display: flex;
  align-items: center;
  width: 5.4375rem;
  height: 1.875rem;
  background: #1f4183;
  border-radius: 0.375rem;
  font-size: $prev-fs;
  color: #fff;
  font-weight: $prev-fw;
  line-height: $prev-lh;
  justify-content: center;
  gap: 0.5rem;
}
.listDiv {
  height: 3.625rem;
  background-color: #fff;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputDiv {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.inputDiv input {
  margin-left: 0.7rem;
}
.label {
  font-weight: 600;
  font-size: $prev-fs;
  line-height: $prev-lh;
  color: #4f4f4f;
}
.deleteEdit {
  padding-right: 4rem;
  display: flex;
  align-items: center;
  gap: 1.75rem;
}
.div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: $prev-fs;
  line-height: $prev-lh;
  color: #a38a8a;
}
