@import "../variables.module";

.filterContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1rem;
}
.title {
  font-size: $modal-topic-fs;
  font-weight: $modal-topic-fw;
  line-height: $modal-topic-lh;
  color: $modal-topic-clr;
}
.filterContainer input,
textarea,
select {
  padding: 0 1.25rem;
  width: 100%;
  height: 3.25rem;
  background: #fafafa;
  border: 2px solid #e7e9ef;
  border-radius: 0.75rem;
  font-size: $modal-inp-fs;
  font-weight: $modal-inp-fw;
  color: $modal-inp-clr;
  line-height: $modal-fill-lh;
  outline: none;
}
.filterContainer textarea {
  height: 10rem;
}

.subTitle {
  font-size: $modal-fill-fs;
  color: $modal-fill-clr;
  line-height: $modal-fill-lh;
}

.searchContainer {
  display: flex;
  // background-color: $main-bg;
  border-radius: 0.625rem;
  align-items: center !important;
  justify-content: space-around;
  overflow: hidden;
  background-color: #fff;
  padding: 0 0.5rem;
  height: 2.5rem;
}

.searchFilterNew {
  align-items: center;
  gap: 1rem;
}
.filterIcon {
  font-size: 1.5rem;
  color: $icon;
  cursor: pointer;
}
.add,
.filter {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  padding: 0 0.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  background-color: #fff;
}
.searchIcon {
  color: $searchIcon;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}
.addText {
  color: $icon;
  font-size: 1.1rem;
}
.searchInput {
  outline: none !important;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #c1c1c1;
}
::placeholder {
  color: #c1c1c1;
}
