.contentCenter {
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.smallSpinner {
  width: 20px;
  height: 20px;
  /* Light grey */
  border-bottom: 5px solid #f3f3f3;
  border-top: 5px solid #d13c39;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.logoLoading {
  width: 5rem;
  height: 100%;
  animation: scaleLogo 0.1s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scaleLogo {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.loadingSpinner {
  width: 70px;
  height: 70px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #d13c39; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
