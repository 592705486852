@import "../../variables.module";

.userContent {
  padding: $padding;
  min-height: calc(100vh - 83.19px);
}

.editor {
  margin-bottom: 2rem !important ;
  width: 100% !important;
}
