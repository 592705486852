@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import "./variables.module";
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins" !important;
  box-sizing: border-box;
}

.ant-select-selector {
  padding: 0.56rem !important;
  width: 100%;
  background: #fafafa !important;
  border: 2px solid #e7e9ef !important;
  border-radius: 0.75rem !important;
  font-size: $modal-inp-fs !important;
  font-weight: $modal-inp-fw !important;
  color: $modal-inp-clr !important;
  line-height: $modal-fill-lh !important;
  height: fit-content !important;
}
.ant-upload-select {
  width: fit-content !important;
}
.filter_searchInput__LCrEx:focus,
.filter_searchInput__LCrEx:hover {
  box-shadow: none !important;
}
.ant-select-selection-search {
  display: flex !important;
  align-items: center !important;
}

// .ant-btn:hover {
//   color: white !important;
// }

.ant-input:focus,
.ant-input:hover,
.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border-color: lightgray;
  // outline: 0;
  // -webkit-box-shadow: 0 0 0 1px lightgray;
  // box-shadow: 0 0 0 1px lightgray;
}
.ant-input-password {
  border-color: lightgray !important;
}
.ant-menu-submenu-title {
  padding: 0 !important;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  margin: 0.5rem 1.2rem !important;
}
::-webkit-scrollbar {
  width: 0px;
}
.container {
  max-width: 100% !important;
  padding: 0;
}
.mainContent {
  background-color: $main-bg;
}

///pagination
.ant-pagination-item-active {
  border: 0 !important;
  /* border-radius: 0.875rem !important; */
  background-color: #d24042 !important;
  margin: 0 0.5rem !important;
}
.ant-pagination-prev,
.ant-pagination-next {
  /* border-radius: 50% !important; */
  /* border: 1px solid #d24042; */
  margin: 0 0.5rem !important;
}

.ant-pagination-item {
  /* border-radius: 50% !important; */
  border: 1px solid #d24042 !important;
  margin: 0 0.5rem !important;
}
.ant-pagination-item-active a {
  color: #fff !important;
}
.ant-pagination {
  padding: 0.5rem !important;
}

// ===========table============
.ant-pagination {
  justify-content: center !important;
}

.ant-menu {
  font-size: 1rem;
}

.ant-table-row {
  cursor: pointer !important;
}
.ant-table-cell {
  color: $userName-color;
  font-size: $userName-fs;
  font-weight: $userName-fw;
  line-height: $userName-lh;
}
.ant-table-cell::before {
  width: 0 !important;
}
:where(.css-dev-only-do-not-override-1i536d8).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  color: $tableHeading-color;
  line-height: $tableHeading-lh;
  position: relative;
  font-size: $tableHeading-fs;
  font-weight: $tableHeading-fw;

  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  transition: background 0.3s ease;
}

// .ant-layout-sider {
//   transition-duration: 0.3s !important;
// }

.ant-upload-list-item-actions a {
  display: none !important;
}
//tooltip

.ant-tooltip-inner {
  color: #5f6465 !important;
  background-color: #fafafa !important;
  width: 200% !important;
}

.ant-tooltip-arrow {
  display: none !important;
}
//modal

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  margin-top: 4rem !important;
}
.ant-btn-default {
  border: $modal-cnl-brd;
  color: #0249af;
  font-size: 0.875rem;
}

.ant-modal-footer button {
  width: $modal-btn-wd;
  height: $modal-btn-ht;
  font-size: 1rem;
  font-weight: 600;
}
//upload
.ant-upload {
  display: flex;
}

//graph
.apexcharts-toolbar {
  display: none !important;
}
#SvgjsG1083 {
  display: none !important;
}
.apexcharts-xaxis-texts-g,
.apexcharts-yaxis-texts-g {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// TODO: jodit editor

.jodit-container {
  width: 50% !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #808080 !important;
  border-color: 808080 !important;
}


.ant-pagination-options-size-changer{
  display: none !important;
}