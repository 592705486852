@import "../../variables.module";

.userDetailContainer {
  padding: $padding;
}

.profileImage {
  // width: 100%;
  border-radius: 50%;
  margin-bottom: 2rem;
  object-fit: cover;
}

.content {
  width: 100%;
  display: flex;
  gap: $padding;
}

.imagePart {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  flex: 1;
}

.descriptionPart {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 2;
  background-color: white;
  border-radius: 1rem;
}
.locationIcon {
  font-size: 1rem;
}
.nameDiv {
  display: flex;
}

.infoDiv {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.info {
  font-weight: 500;
  font-size: 1.2rem;
}
.rankingText {
  text-transform: uppercase;
  color: gray;
  font-weight: 500;
  font-size: 0.8rem;
}
.stars {
  display: flex;
  align-items: center;
  height: fit-content;
}
.starDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.starText {
  font-size: 1.5rem;
  font-weight: 500;
}
.contactDiv {
  background-color: #86a4b3;
  border-radius: 0.4rem;
  padding: 0.2rem;
  color: #fff;
}
.msgDiv {
  font-weight: 500;
}

.optionsDiv {
  padding: 1rem 0;
}
.timelineBtnInactive,
.aboutBtnInactive {
  color: gray;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}
.timelineBtn,
.aboutBtn {
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  transform: scale(1.1);
}

.contactInfoDiv,
.basicInfoDiv {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.basicInfoDiv {
  margin-top: 2rem;
}

.section {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
  color: gray;
}
.hr {
  width: 100%;
  background-color: rgb(193, 190, 190);
  height: 1px;
}

.priority {
  background-color: #86a4b3;
  border-radius: 0.4rem;
  padding: 0.2rem 0.5rem;
  color: #fff;
}
.workLocation {
  color: gray;
  font-weight: 500;
}
.eachWork {
  margin: 1.5rem 0;
}
.skillDiv ul li {
  list-style: none;
}

.tabs {
  padding: 1rem;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}
