@import "../../variables.module";

.homePageContainer {
  padding: $padding;
}
.name {
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 3.375rem;
  color: #4b4848;
}
.wlcm {
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #b4b4b4;
}
.graphPart {
  display: flex;
  flex-direction: column;
  flex: 4.5;
  gap: $padding;
}
.welcome {
  display: flex;
  flex-direction: column;
}
.mainDiv {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.recentBlog {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem 1rem;
}
.eachDiv {
  width: 100%;
  height: fit-content;
  background: #e8e7ff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.2rem;
}
.timeDate,
.blogTitle {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 0.5625rem;
  line-height: 0.875rem;
  color: #000000;
}
.blogTitle {
  font-size: 0.75rem;
}
.barRadial {
  display: flex;
  gap: 1.5rem;
}
.barGraph {
  background-color: #fff;
  flex: 2;
  border-radius: 0.75rem;
  padding: 1.5rem 1rem;
}
.radialGraph {
  flex: 1;
  background-color: #fff;
  border-radius: 0.75rem;
}
.cardContainer {
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); // Adjust column count based on screen size
  gap: 16px; // Space between grid items
}

.gridItem {
  background-color: #fff; // Optional: You can apply styles for grid items
  padding: $padding;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Optional: Card-like appearance
}
