@import "../../variables.module";

.testimonialContainer {
  padding: $padding;
  min-height: calc(100vh - 83.19px);
}
.qn {
  font-weight: 500;
  font-size: $role-fs;
  line-height: $role-lh;
  color: #000000;
  cursor: pointer;
}
.cardContainer {
  background-color: #fff;
  border-radius: $Tcard-br;
  display: flex;
  flex-direction: column;
  height: 13.4375rem;
  height: fit-content;
  box-shadow: $Tcard-bs;
}
.badgeIconContainer {
  width: 5.5rem;
  height: 5.5rem;
  background: #ebf2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  border-radius: 50%;
}

.qnSection {
  height: 3.75rem;
  position: relative;
  padding-left: 1.6875rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  position: relative;
}
.tabContainer {
  gap: 1rem;
}
.tabs,
.inactiveTab {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d24042;
  color: #fff;
  width: 7rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 1rem;
}
.inactiveTab {
  background-color: #f57173;
}
.stars {
  position: absolute;
  top: 1.6875rem;
  left: 6.3125rem;
}
.dots {
  cursor: pointer;
}
.aboutCard {
  padding-left: 1.6875rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding-right: 1.1875rem;
  padding-bottom: 0.5rem;
}
.dataDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.brief {
  font-size: $brief-fs;
  font-weight: 400;
  line-height: $brief-lh;
  color: $brief-color;
}
.pagination {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.modalSignup {
  height: 44rem;
  width: 43.875rem !important;
}

.searchContainer {
  display: flex;
  border-radius: 1.125rem;
  align-items: center !important;
  justify-content: space-around;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
}
.searchFilterNew {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}
.filterIcon {
  font-size: 1.5rem;
  color: #dbd4d5;
  cursor: pointer;
}
.searchInput {
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #c1c1c1;
}
::placeholder {
  color: #c1c1c1;
}

.editIcon {
  cursor: pointer;
}

.editDiv {
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: 10;
  width: fit-content;
  height: fit-content;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem 0 0.5rem 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.noEditDiv {
  display: none;
}
.optionDiv {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $hr;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.optionDiv:last-child {
  border: none;
  margin-bottom: 0;
}
