@import "../../variables.module";

.donorListContent {
  padding: $padding;
  min-height: calc(100vh - 83.19px);
}
.profileImage {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.editIcon {
  cursor: pointer;
}

.editDiv {
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: 10;
  width: fit-content;
  height: fit-content;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem 0 0.5rem 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.optionDiv {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $hr;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.optionDiv:last-child {
  border: none;
  margin-bottom: 0;
}

.activeDonor,
.inactiveDonor {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
}
.activeDonor {
  background-color: #f3fcf7;
  color: #14c25a;
}
.inactiveDonor {
  background-color: #f9f9f9;
  color: #939393;
}

.card {
  background-color: #fff;
  border-radius: 0.625rem;
  width: 16.6875rem;
  height: 7.25rem;
}
.eachCard {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.625rem;
}
.imgContainer {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf2ff;
}
.cardNumber {
  display: flex;
  flex-direction: column;
}
.value {
  color: #1c1b2b;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.title {
  color: #aaa9b0;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.25rem;
}
.cardContainer {
  padding-bottom: $padding;
}
.searchContainer {
  display: flex;
  // background-color: $main-bg;
  border-radius: 0.625rem;
  align-items: center !important;
  justify-content: space-around;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
}
.searchFilterNew {
  align-items: center;
  gap: 1rem;
}
.filterIcon {
  font-size: 1.5rem;
  color: $icon;
  cursor: pointer;
}
.add,
.filter {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.625rem;
  background-color: #fff;
}
.searchIcon {
  color: $searchIcon;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}
.addText {
  color: $icon;
  font-size: 1.1rem;
}
.searchInput {
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #c1c1c1;
}
::placeholder {
  color: #c1c1c1;
}

.modalSignup {
  height: 44rem;
  width: 43.875rem !important;
}
