@import "../../variables.module";

.topBarContainer {
  height: 5.2rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topBarContainerHr {
  height: 5.2rem;
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid $hr;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topicIcon {
  display: flex;
  margin-left: $padding;
  gap: 1rem;
}
.addPartnerDiv {
  cursor: pointer;
  background-color: #262C31;
  border-radius: 0.5rem;
  width: 2.75rem;
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topBarContainer h5,
.topBarContainerHr h5 {
  color: $topic-color;
  font-weight: $topic-fw;
}
.profileSide {
  justify-content: space-between !important;
  align-items: center;
  gap: 2rem;
  margin-right: $padding;
}
.searchContainer {
  display: flex;
  background-color: $main-bg;
  width: 22.31rem;
  height: 2.94rem;
  position: absolute;
  bottom: 0.5625rem;
  left: 50%;
  transform: translate(-50%);
  border-radius: 1.125rem;
  align-items: center !important;
  justify-content: space-around;
}
.searchInput {
  width: 80% !important;
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #c1c1c1;
}
::placeholder {
  color: #c1c1c1;
}
.notificationIcon,
.settingIcons {
  color: $icon;
  font-size: 1.5rem;
  cursor: pointer;
}
.settingIcons {
  font-size: 1.2rem;
}
.name {
  font-size: $name-fs;
  font-weight: $name-fw;
  line-height: $name-lh;
  color: $name-color;
  margin-bottom: -0.2rem;
}
#stInputOld,
#stInputNew,
#stInputConfirm {
  border: none;
  width: 100%;
  color: black;
}
.error {
  color: red;
}

.post {
  font-size: $post-fs;
  font-weight: $post-fw;
  line-height: $post-lh;
  color: $post-color;
}

//setting modal css

.settingsContainer {
  position: absolute;
  right: 0%;
  top: 110%;
  width: 20rem;
  // height: 12.75rem;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 2;
}

.settingTopic {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #858585;
}
.settingInput {
  background: #f0f1f3;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 1rem;
  width: 100%;
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.5rem;
  color: #42464b;
}
.settingOption {
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 1.5rem;
  color: #42464b;
}

.passEye {
  position: relative;
  float: right;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.btn {
  width: 10rem;
  height: 2.5rem;
  border: none;
  background-color: $logo-text-color;
  border-radius: 0.5rem;
  font-weight: 600;
  color: #fff;
  margin-top: 1rem;
}
.formPw {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}
.labelInput {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.inputEye {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border: 2px solid #e7e9ef;
  border-radius: 12px;
  padding-right: 1rem;
}

.formPw label {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  color: #8998ae;
}
.formPw input {
  background: #fafafa;
  border: 2px solid #e7e9ef;
  border-radius: 12px;
  padding: 1rem;
  outline: none;
}
.doneBtn {
  background: #0249af;
  border-radius: 6px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f4f6fa;
  padding: 1rem 0;
  margin-top: 1rem;
}
