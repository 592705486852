@import "../../variables.module";

.container {
  padding: $padding;
  min-height: calc(100vh - 83.19px);
}

.searchContainer {
  display: flex;
  justify-content: center;
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #fff;
  border: 1px solid #ddd;
  overflow: hidden;
  width: 100%;
  padding: 0 1rem;
}
.searchInput {
  padding: 0.5rem;
  outline: none;
  border: none;
  width: 100%;
}
.searchIcon {
  cursor: pointer;
  font-size: 1.5rem;
}
.websiteSettings {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.searchFilterNew {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.logoImage {
  width: 6rem !important;
  height: 6rem !important;
  border-radius: 50%;
  object-fit: contain;
  background-color: red;
}
.contactChangeDiv {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contactChange {
  display: flex;
  flex-direction: column;
}
.changeInput {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 50%;
  outline: none;
}

.checkBox {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.saveBtn,
.cancelBtn {
  background-color: $active-icon-bg;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: 500;
}
.cancelBtn {
  background-color: $logo-text-color;
}
.textarea {
  width: 50%;
  height: 10rem;
}

.activeDonor,
.inactiveDonor {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem;
}
.activeDonor {
  background-color: #f3fcf7;
  color: #14c25a;
}
.inactiveDonor {
  background-color: #f9f9f9;
  color: #939393;
}
