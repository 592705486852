// main
$main-bg: #f4f6f8;
$padding: 1.875rem;
$loginBg: #cc2c49;

//navbar
$navbar-bg: #262c31;
$navbar-option-bg: #033595;
$navbar-option-br: 0.5rem;
$option-active-color: #fafafa;
// $navbar-br: 1.375rem;
$logo-text-color: #d14144;
$option-font-size: 1rem;
$options-line-height: 1.5rem;
$options-inactive-color: #999aae;
$active-icon-bg: #2f43a9;
$active-icon-br: 0.5rem;

// topbar
$topbar-br: 0px 1.375rem 0px 0px;
$topic-color: #4a3637;
$topic-fs: 1.25rem;
$topic-fw: 700;
$name-fs: 0.75rem;
$name-fw: 600;
$name-lh: 1.125rem;
$name-color: #000;
$post-fs: 0.625rem;
$post-fw: 600;
$post-lh: 0.9375rem;
$post-color: #afafaf;
$hr: #d1ced4;

//users
$userName-fs: 0.8125rem;
$userName-fw: 500;
$userName-lh: 0.93rem;
$userName-color: #5f6265;

$tableHeading-fs: 0.75rem;
$tableHeading-fw: 500;
$tableHeading-lh: 1.125rem;
$tableHeading-color: #929297;

///////blood Needs
$urgent-color: #c23e14;
$moderate-color: #c2bb14;
$non-urgent-color: #8ac214;

// testimonials

$Tcard-bs: 0px 0.25rem 2.25rem rgba(0, 0, 0, 0.1);
$Tcard-br: 0.75rem;

$role-color: #838093;
$role-fw: 400;
$role-fs: 0.875rem;
$role-lh: 1.3125rem;

$name-fw: 600;
$name-fs: 1.25rem;
$name-lh: 1.875rem;
$name-color: #000;

$brief-fw: 500;
$brief-fs: 0.75rem;
$brief-lh: 1.125rem;
$brief-color: #838093;

//categories
$categories-bg: #262c31;
$prev-fw: 500;
$prev-fs: 0.9375rem;
$prev-lh: 1.375rem;
$prev-clr: #929292;
$cur-clr: #b4b4c5;
$heading-clr: #b4b4c5;

//modal
$modal-topic-fw: 600;
$modal-topic-fs: 1.375rem;
$modal-topic-lh: 2.0625rem;
$modal-topic-clr: #2e3f55;

$modal-fill-fs: 1rem;
$modal-fill-lh: 1.5rem;
$modal-fill-clr: #8998ae;

$modal-inp-fw: 500;
$modal-inp-fs: 0.875rem;
$modal-inp-lh: 1.3125rem;
$modal-inp-clr: #7c8ca7;

$modal-btn-wd: 7.75rem;
$modal-btn-ht: 2.75rem;
$modal-sav-btn: #0249af;
$modal-cnl-brd: 1.6px solid #0249af;

//icons

$icon: #62656d;
$searchIcon: #c1c3c5;


  $unreadNotification: '#FFEEED',