.container {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 16px;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.3s;
}
.container:hover{
  scale: 1.05;
}

.main {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 10px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 102px;
  cursor: pointer;
  // margin-right: 16px;
  position: relative;
  overflow: hidden !important;
}

.img {
  object-fit: cover;
}

.deleteIconContainer {
  margin-top: 0.5rem;
}
