@import "../../variables.module";

.testimonialContainer {
  padding: $padding;
  min-height: calc(100vh - 83.19px);
}
.cardContainer {
  background-color: #fff;
  border-radius: $Tcard-br;
  display: flex;
  flex-direction: column;
  // height: 16.1875rem;
  height: fit-content;
  padding-bottom: 1rem;
  box-shadow: $Tcard-bs;
  // margin-top: 1rem;
}
.badgeIconContainer {
  width: 5.5rem;
  height: 5.5rem;
  background: #ebf2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  border-radius: 50%;
  overflow: hidden;
}
.profileStarSection {
  height: 3.75rem;
  border-bottom: 0.125rem solid #f4f4f4;
  position: relative;
  padding-left: 1.6875rem;
  margin-bottom: 2.5rem;
}
.profile {
  width: 100%;
  height: 100%;
}
.stars {
  position: absolute;
  top: 1.6875rem;
  left: 6.3125rem;
}
.dots {
  position: absolute;
  top: 1.1875rem;
  right: 1.1875rem;
  cursor: pointer;
}

.aboutCard {
  padding-left: 1.6875rem;
  display: flex;
  flex-direction: column;
  gap: 0.675rem;
  padding-right: 1.1875rem;
}

.role {
  color: $role-color;
  line-height: $role-lh;
  font-size: $role-fs;
  font-weight: $role-fw;
}

.name {
  font-size: $name-fs;
  font-weight: $name-fw;
  line-height: $name-lh;
  color: $name-color;
  margin-top: 1.25rem;
}

.brief {
  font-size: $brief-fs;
  font-weight: $brief-fw;
  line-height: $brief-lh;
  color: $brief-color;
}
.dataDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.modalSignup {
  height: 44rem;
  width: 43.875rem !important;
}
.searchContainer {
  display: flex;
  // background-color: $main-bg;
  border-radius: 1.125rem;
  align-items: center !important;
  justify-content: flex-end;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
}
.searchFilterNew {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.filterIcon {
  font-size: 1.5rem;
  color: $icon;
  cursor: pointer;
}
.searchIcon {
  color: $searchIcon;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}
.addText {
  color: $icon;
  font-size: 1.1rem;
}
.searchInput {
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #c1c1c1;
}
::placeholder {
  color: #c1c1c1;
}

.editIcon {
  cursor: pointer;
}

.editDiv {
  position: absolute;
  top: 50%;
  right: 7%;
  z-index: 10;
  width: fit-content;
  height: fit-content;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem 0 0.5rem 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.optionDiv {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $hr;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.optionDiv:last-child {
  border: none;
  margin-bottom: 0;
}
